import { FunctionComponent } from 'react'
import styled, { DefaultTheme } from 'styled-components'

interface ITextProps {
  attr?: 'span' | 'p'
  color?: string
  fontSize?: number
  tabletFontSize?: number
  desktopFontSize?: number
  fontWeight?: number
  fontStyle?: 'italic' | 'bold'
  lineHeight?: string
  tabletLineHeight?: string
  desktopLineHeight?: string
  textAlign?: 'center' | 'right'
  type?: 'error' | 'default'
  paragraph?: boolean
  uppercase?: boolean
}

interface ITextPropsArg extends ITextProps {
  theme: DefaultTheme
}
const getColor = (props: ITextPropsArg) => {
  if (props.type === 'error') return props.theme.colors.red5

  return props.color ?? 'inherit'
}

const StyledTextDiv = styled.div<ITextProps>`
  margin: 0;
  padding: 0;

  color: ${props => getColor(props)};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'inherit')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  ${props =>
    props.uppercase
    && `
    text-transform: uppercase;
  `}

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.tabletFontSize && `font-size: ${props.tabletFontSize}px;`}
    ${props => props.tabletLineHeight && `line-height: ${props.tabletLineHeight};`}
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    ${props => props.desktopFontSize && `font-size: ${props.desktopFontSize}px;`}
    ${props => props.desktopLineHeight && `line-height: ${props.desktopLineHeight};`}
  }
`

const StyledParagraph = styled(StyledTextDiv).attrs({ as: 'p' })``
const StyledSpan = styled(StyledTextDiv).attrs({ as: 'span' })``

const Text: FunctionComponent<ITextProps> = ({
  attr,
  children,
  color,
  fontSize,
  tabletFontSize,
  desktopFontSize,
  fontWeight,
  fontStyle,
  lineHeight,
  tabletLineHeight,
  desktopLineHeight,
  textAlign,
  paragraph,
  uppercase,
  type = 'default',
}) => {
  let StyledText = StyledTextDiv
  if (attr === 'p' || paragraph) StyledText = StyledParagraph

  if (attr === 'span') StyledText = StyledSpan

  return (
    <StyledText
      color={color}
      fontSize={fontSize}
      tabletFontSize={tabletFontSize}
      desktopFontSize={desktopFontSize}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      lineHeight={lineHeight}
      tabletLineHeight={tabletLineHeight}
      desktopLineHeight={desktopLineHeight}
      textAlign={textAlign}
      paragraph={paragraph}
      uppercase={uppercase}
      type={type}
    >
      {children}
    </StyledText>
  )
}

export default Text
