import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useSWRConfig } from 'swr'

import SocialSignOn from 'modules/SignUp/SocialSignOn'

import ErrorBox from 'components/ErrorBox'
import CheckboxInput from 'components/GlobalStyles/Inputs/CheckboxInput'
import PasswordInput from 'components/GlobalStyles/Inputs/PasswordInput'
import TextInput from 'components/GlobalStyles/Inputs/TextInput'
import Text from 'components/GlobalStyles/Text'
import NotificationToast from 'components/NotificationToast'
import OrDivider from 'components/OrDivider'
import RoundedButton from 'components/RoundedButton'
import Spacer from 'components/Spacer'

import { loginUser } from 'services/auth'
import { useUserListings } from 'services/swr/listings/useUserListings'
import { useUser } from 'services/swr/useUser'

import * as E from 'types/enums'

import * as T from './types'

const MaxWidthContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 32px;
  margin: 0 auto;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 0 48px;
    max-width: 820px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    padding: 0;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SocialErrorContainer = styled.div`
  width: 100%;
  max-width: 356px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    max-width: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 356px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    align-items: flex-start;
    flex-direction: row;
    max-width: 100%;
  }
`

const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 356px;
  }
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.colors.neutral500};
`

const SmallText = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.colors.neutral500};

  & > span {
    cursor: pointer;
    color: ${props => props.theme.colors.blue};
  }
`

const SignIn: FunctionComponent = () => {
  const theme = useContext(ThemeContext)

  const { mutate } = useSWRConfig()

  const router = useRouter()
  const { redirect } = router.query

  const [error, setError] = useState('')
  const [ssoError, setSsoError] = useState('')
  const resetError = () => setError('')

  const [isSignInLoading, setIsSignInLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [credentials, setCredentials] = useState<T.SignInCredentials>({
    email: '',
    password: '',
  })

  const [rememberMe, setRememberMe] = useState(true)

  const { user, mutate: revalidateUser } = useUser()
  const { userListings } = useUserListings()

  const handleCredentialsChange = (name: string, value: string) => {
    resetError()
    const newCredentials = { ...credentials }
    newCredentials[name] = value
    setCredentials(newCredentials)
    if (submitted) setSubmitted(false)

    setSsoError('') // reset sso error
  }

  const handleSignInSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    setSubmitted(true)
    const { email, password } = credentials
    if (!email && !password) return setError('Email and password are required.')

    if (!email) return setError('Email is required.')

    if (!password) return setError('Password is required.')

    setIsSignInLoading(true)
    try {
      await loginUser(email, password, rememberMe)

      /*
       * if there's a redirect it means any stale swr (error) data from the page we were on
       * is now out of date. when we route back to it after signin, by default we'll briefly
       * see the old error while we're successfully fetching with our shiny new user token;
       * so we must clear the cache pre-emptively in order to clear the error
       */
      if (redirect) mutate(() => true, undefined, { revalidate: false })

      // finally, refetch the user to trigger route push
      revalidateUser()
    } catch (err) {
      setIsSignInLoading(false)
      return setError('Your email or password was incorrect. Please try again.')
    }
  }

  const toggleCheckbox = () => setRememberMe(state => !state)

  useEffect(() => {
    if (error && submitted) setSubmitted(false)

    if (error && isSignInLoading) setIsSignInLoading(false)
  }, [error])

  useEffect(() => {
    if (!userListings || !user) return

    if (redirect) {
      router.push(redirect as string)
      return
    }

    if (userListings.length === 1 && userListings[0].listingStatus.name === E.ListingStatus.draft) {
      router.push('/listing-flow')
      return
    }

    if (userListings.length > 1) {
      router.push('/listing/dashboard')
      return
    }

    router.push('/listing-flow')
  }, [user, userListings])

  const handleSsoError = (errMessage: string) => {
    if (!errMessage) return

    setSsoError(errMessage)
  }

  return (
    <MaxWidthContainer>
      <Spacer
        size={36}
        desktopSize={84}
      />
      <Container>
        <Text
          fontSize={24}
          tabletFontSize={32}
          desktopFontSize={40}
          color={theme.colors.neutral900}
          fontWeight={800}
          textAlign="center"
        >
          Account Login
        </Text>
        <Spacer
          size={24}
          tabletSize={16}
          responsiveSize={12}
        />
        <Text
          fontSize={16}
          tabletFontSize={18}
          desktopFontSize={20}
          fontWeight={500}
          color={theme.colors.neutral400}
          textAlign="center"
        >
          Log in below to continue your listing and see your savings.
        </Text>
        <Spacer
          size={24}
          desktopSize={56}
        />
        {ssoError && (
          <SocialErrorContainer>
            <NotificationToast errorText={ssoError} />
          </SocialErrorContainer>
        )}
        <ContentContainer>
          <StyledForm onSubmit={handleSignInSubmit}>
            <Label>Email</Label>
            <Spacer size={4} />
            <TextInput
              name="email"
              placeholder="Email"
              placeholderTextColor={theme.colors.neutral500}
              value={credentials.email}
              submissionError={submitted && !credentials.email}
              onChange={() => {}}
              onChangeImmediate={handleCredentialsChange}
              inputHeight={52}
              borderRadius="10px"
            />
            <Spacer size={16} />
            <Label>Password</Label>
            <Spacer size={4} />
            <PasswordInput
              name="password"
              error={submitted && !credentials.password}
              value={credentials.password}
              placeholderTextColor={theme.colors.neutral500}
              background={theme.colors.transparentGrey30Alpha50}
              borderColor={theme.colors.grey30}
              textColor={theme.colors.neutral900}
              onChange={handleCredentialsChange}
              inputHeight={52}
              borderRadius="10px"
            />
            <Spacer size={16} />
            <FlexRowContainer>
              <CheckboxInput
                name="rememberMe"
                label="Remember me"
                checked={rememberMe}
                onChange={toggleCheckbox}
              />
              <SmallText>
                <Link href="/password/request">
                  <span>Forgot password?</span>
                </Link>
              </SmallText>
            </FlexRowContainer>
            <Spacer size={24} />
            <RoundedButton
              text={isSignInLoading ? 'Logging In...' : 'Log In'}
              background={theme.colors.blue4}
              color={theme.colors.white}
              type={E.ButtonType.submit}
              loading={isSignInLoading}
              disabled={isSignInLoading}
              height={44}
              radius={24}
              boxShadow={`0px 8px 12px ${theme.colors.transparentBlue4Alpha25}`}
              fullWidth
            />
            {error && <Spacer size={20} />}
            <ErrorBox error={error} />
            <Spacer size={24} />
            <SmallText>
              {"Don't have an account? "}
              <Link href="/listing/create">
                <span>Sign up.</span>
              </Link>
            </SmallText>
          </StyledForm>
          <OrDivider />
          <SocialSignOn handleSsoError={handleSsoError} />
        </ContentContainer>
      </Container>
      <Spacer
        size={82}
        responsiveSize={36}
      />
    </MaxWidthContainer>
  )
}

export default SignIn
