import { NextPage, NextPageContext } from 'next'
import Head from 'next/head'

import SignIn from 'modules/SignIn'

import ApiCreator from 'services/api'
import { useCloseHeaderModal } from 'services/hooks'

const SignInPage: NextPage = () => {
  useCloseHeaderModal()

  return (
    <>
      <Head>
        <title>Sign In - HomeLister</title>
        <meta
          name="description"
          content="Sign into your Account at HomeLister"
        />
        <meta
          name="keywords"
          content="Sign In, Login, My Account, HomeLister"
        />
      </Head>
      <SignIn />
    </>
  )
}

export const getServerSideProps = async (ctx: NextPageContext) => {
  try {
    // reroute serverside if user is logged in when requesting page
    const api = new ApiCreator(ctx)
    const response = await api.getMe()
    if (!response.ok) throw new Error(response.problem)

    if (response.data) {
      return {
        redirect: {
          permanent: false,
          destination: ctx?.query?.redirect || '/',
        },
      }
    }

    return { props: {} }
  } catch (err) {
    return { props: {} }
  }
}

export default SignInPage
